export enum Roles {
  LessonAdmin = 1,
  LessonReadOnly = 2,
  CustomerAdmin = 3,
  CustomerReadOnly = 4,
  CustomerUser = 5,
  Reports = 6,
  ResourcesAdmin = 7,
  ResourcesReadOnly = 8,
  SuperAdmin = 9,
  LessonRestricted = 10,
  MSHA_Instructor = 11,
  Wraps = 12,
  MarketingAdmin = 13,
  MarketingReadOnly = 14,
  NotSet = -1,
  IndSkillsAdmin = 15,
  All = -1000,
}

export function RoleMap() {
  const map = [
    { id: 1, name: 'LessonAdmin' },
    { id: 2, name: 'LessonReadOnly' },
    { id: 3, name: 'CustomerAdmin' },
    { id: 4, name: 'CustomerReadOnly' },
    { id: 5, name: 'CustomerUser' },
    { id: 6, name: 'Reports' },
    { id: 7, name: 'ResourcesAdmin' },
    { id: 8, name: 'ResourcesReadOnly' },
    { id: 9, name: 'SuperAdmin' },
    { id: 10, name: 'LessonRestricted' },
    { id: 11, name: 'MSHA Instructor' },
    { id: 12, name: 'Wraps' },
    { id: 13, name: 'MarketingAdmin' },
    { id: 14, name: 'MarketingReadOnly' },
    { id: -1, name: 'NotSet' },
    { id: -1000, name: 'All' },
  ];

  return map.sort((role1, role2) => {
    return role1.name > role2.name ? 1 : role2.name > role1.name ? -1 : 0;
  });
}
