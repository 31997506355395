
  <div id="icon-logo" class="justify-items-center align-items-center grid p-3 text-center mb-1">
    <img src="assets/hsi_logo_small.png" alt="logo" style="width: 100px; min-width: 50px;" />
  </div>
  <div id="legacy-login-form" class="d-flex flex-column justify-content-center align-items-center" [hidden]="!this.showSSOForm">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field style="width: 100%">
        <mat-label>Username</mat-label>
        <input type="text" matInput placeholder="Username" formControlName="Admin_ID" />
      </mat-form-field>
    
      <mat-form-field style="width: 100%">
        <mat-label>Password</mat-label>
        <input type="password" matInput placeholder="Password" formControlName="Password" />

      </mat-form-field>
      <button mat-flat-button color="primary" [disabled]="!this.loginForm.valid" class="btn btn-primary rounded-0 shadow-md mr-1">Login</button>
      <button mat-flat-button color="error" class="btn btn-primary rounded-0 shadow-md " (click)="cancelLegacyLogin($event)">Cancel</button>

    </form>
    
  </div>

  <div id="login-buttons" class="flex-column justify-content-center align-items-center grid place-self-center" [hidden]="this.hideForm">
    <button mat-raised-button color="primary" class="btn btn-primary rounded-0 shadow-md m-2" (click)="this.showLegacyForm()" [hidden]="this.showSSOForm">Login with  Username and Password</button>
    <button mat-raised-button color="primary" class="btn btn-primary rounded-0 shadow-md m-2" (click)="LoginWithSSO($event)">Login with SSO</button>
    <button mat-button color="primary" (click)="openResetDialog($event)">Forgot Password</button>
  </div>
