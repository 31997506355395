import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { AuthStateService } from '@BaseServices/auth-state.service';

@Component({
  selector: 'app-sso',
  standalone: true,
  imports: [],
  templateUrl: './sso.component.html',
  styleUrl: './sso.component.css',
})
export class SSOComponent {

  constructor(private authStateService: AuthStateService, private ssoService: MsalService) {

  this.ssoService.handleRedirectObservable().subscribe({
    next: (result: AuthenticationResult) => {
      if( result && !this.authStateService.IsLoggedIn()) {
          if(result != null && result.account !== null){
        
          const profile_data = result.account as AccountInfo;
          console.debug("Login Success: SSO Component ");
          this.authStateService.loginSSO({ email: profile_data.username, accessToken: profile_data.idToken });
        }  
      }
    },
    error: (error) => {
      console.error("Failed to Login: "+ error);
    }
    });
  }
}
