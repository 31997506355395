import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { PasswordResetDialogComponent } from '@LoginComponent/dialogs/password-reset-dialog/password-reset-dialog.component';
import { filter } from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public email: string;

  @Output() login = new EventEmitter<{ Username: string; Password: string }>();
  @Output() loginSSO = new EventEmitter<{ email: string; accessToken: string }>();

  @Output() passwordReset = new EventEmitter<{ Username: string; Email: string }>();

  private loginInProgress = false;
  protected showSSOForm = false;
  protected hideForm = true;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private authStateService: AuthStateService, private ssoService: MsalService,@Inject(MSAL_GUARD_CONFIG) private ssoGuardConfig: MsalGuardConfiguration, private ssoBroadcastService: MsalBroadcastService, private router: Router
) {
    this.loginForm = this.formBuilder.group({
      Admin_ID: ['', Validators.required],
      Password: ['', Validators.required],
    });
  }

  ngOnInit() {
    console.debug('Login Form Component Initialized');

    if(this.authStateService.IsLoggedIn()) {
      this.router.navigate(['/home']);
    }
    
    this.ssoService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
      },
      error: (error) => {
        console.error("Failed to Login: "+ error);
        this.loginInProgress = false;
        this.ssoService.loginRedirect();
        this.loginForm.enable();
      }
    });

    this.ssoBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.LOGOUT_SUCCESS ||
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
          )
      )
      .subscribe((result: EventMessage) => {
        switch(result.eventType) {
          case EventType.LOGIN_SUCCESS:
            if(!this.authStateService.IsLoggedIn() && !this.loginInProgress) {
              this.loginInProgress = true;
              
              console.debug('Login Success: Login Component');
              const resultData = result.payload as AuthenticationResult;
              const userData = resultData.account as AccountInfo;
              this.authStateService.loginSSO({ email: userData.username, accessToken: userData.idToken });
            }   
          
            break;
          case EventType.LOGOUT_SUCCESS:
            console.debug('logout success');
            if(this.ssoService.instance) {
              this.ssoService.instance.clearCache()
            }
            break;
          case EventType.LOGIN_FAILURE:
            console.debug('login failure');
            if(this.ssoService.instance) {
              this.ssoService.instance.clearCache()
            }
            break;
          case EventType.ACQUIRE_TOKEN_FAILURE:
            console.debug('Acquire Token failure');
            if(this.ssoService.instance) {
              this.ssoService.instance.clearCache()
            }
            this.ssoService.loginRedirect();
            break;
        }
      });

      setTimeout(() => {
        //Hide main form for 3 seconds. This is to show the SSO login button first
        this.hideForm = false;
      }, 1000);
  }

  ssoLogin() {
    this.loginForm.disable();
    if (this.ssoGuardConfig.authRequest){
      this.ssoService.loginRedirect({...this.ssoGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.ssoService.loginRedirect();
    }
  }

  LoginWithSSO(e) {
    e.preventDefault();

    this.showSSOForm = false;

    if(this.ssoService.instance) {
      const account = this.ssoService.instance.getAllAccounts()[0];
      if(account) {
        this.loginSSO.emit({ email: account.username, accessToken: account.idToken });
      }
      else {
        this.ssoLogin();
      }
    } 
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.login.emit(this.loginForm.value);
    }
  }

  openResetDialog($event) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(PasswordResetDialogComponent, {
      position: { top: '100px' },
      maxWidth: '400px',
      width: '80%',
      data: { email: this.email },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('The dialog was closed', result);
        this.email = result;
        this.passwordReset.emit(result);
      }
    });
  }
 
  showLegacyForm() {
    this.showSSOForm = true;
  }

  cancelLegacyLogin(event: any) {
    event.preventDefault()
    this.showSSOForm = false;
  }

  ngOnDestroy(): void {
    console.debug('Login Form Component Destroyed');
  }

}
