import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Helpers
import { AuthInterceptor } from '@Resolvers/auth.interceptor';
import { LibraryResolver } from '@Resolvers/content-resolvers/library.resolver';

import { LmsResolver } from '@Resolvers/distribution-resolvers/lms.resolver';
import { SalesRepResolver } from '@Resolvers/distribution-resolvers/sales-rep.resolver';
import { TransactionResolver } from '@Resolvers/distribution-resolvers/transactions.resolver';
import { ContactResolver } from '@Resolvers/distribution-resolvers/contact.resolver';
import { WrapResolver } from '@Resolvers/distribution-resolvers/wraps.resolver';
// root
import { AppComponent } from './app.component';

//home
import { HomePageComponent } from '@HomeComponent/home-page/home-page.component';

//login
import { LoginPageComponent } from '@LoginComponent/pages/login-page/login-page.component';
import { LoginFormComponent } from '@LoginComponent/forms/login-form/login-form.component';

//Shared/Navigation
import { NavbarComponent } from '@Shared/layout/navbar/navbar.component';
import { LoadingIndicatorComponent } from '@Shared/layout/loading-indicator/loading-indicator.component';

//Shared modules
import { SharedModule } from '@Shared/shared.module';
import { PreviewerModule } from './previewer/previewer.module';
import { PasswordResetDialogComponent } from './login/dialogs/password-reset-dialog/password-reset-dialog.component';

import { FeatureFlagsService } from './services/feature-flags/feature-flags.service';

//used for SSO
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from "@azure/msal-angular";
import { PublicClientApplication, BrowserCacheLocation, InteractionType, LogLevel } from '@azure/msal-browser';
import { environment } from '@Enviornments/environment';

const msalConfig = environment.SSO_Config;
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log("MSAL: "+message);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    LoginFormComponent,
    NavbarComponent,
    HomePageComponent,
    LoadingIndicatorComponent,
    PasswordResetDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    PreviewerModule,
    MsalModule.forRoot( new PublicClientApplication({ // MSAL Configuration
      auth: {
          clientId: msalConfig.clientId,
          authority: msalConfig.authority,
          redirectUri: msalConfig.redirectUri,
      },
      cache: {
          cacheLocation : BrowserCacheLocation.SessionStorage,
          storeAuthStateInCookie: false, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: environment.production? LogLevel.Error : LogLevel.Error,
          piiLoggingEnabled: false
      },
      }
  }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
  }, {interactionType: InteractionType.Redirect, protectedResourceMap: new Map([])}
), 
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (featureFlagsService: FeatureFlagsService) => () => featureFlagsService.initialize(),
      deps: [FeatureFlagsService],
      multi: true
    }
    , {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor, multi: true
    }
    , LibraryResolver
    , LmsResolver
    , SalesRepResolver
    , TransactionResolver
    , ContactResolver
    , WrapResolver,
    MsalService,
    MsalGuard,
    MsalBroadcastService],
  bootstrap: [AppComponent],
})
export class AppModule {}
