import { Component, OnInit } from '@angular/core';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { LoadingStateService } from '@BaseServices/loading-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit {
  public loading$: Observable<boolean>;

  constructor(private loadingStateService: LoadingStateService, private authStateService: AuthStateService) {
    this.loading$ = this.loadingStateService.loading$;
  }

  ngOnInit() {}

  onLogin(formData: any) {
    this.authStateService.login(formData);
  }
  
  onSSOLogin(formData: any) {
    this.authStateService.loginSSO(formData);
  }
  onPasswordReset(formData: any) {
    this.authStateService.sendResetPasswordRequest(formData);
  }
}
