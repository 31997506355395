import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// login / base / home
import { LoginPageComponent } from '@LoginComponent/pages/login-page/login-page.component';
import { HomePageComponent } from '@HomeComponent/home-page/home-page.component';

// Helpers / Guards
import { AuthGuard } from '@Resolvers/auth.guard';
import { AuthCustomerGuard } from '@Resolvers/auth-customer.guard';
import { RoleGuard } from '@Resolvers/role.guard';
import { Roles } from '@Models/auth/UserRoles';
import { PasswordResetPageComponent } from '@LoginComponent/pages/password-reset-page/password-reset-page.component';
import { SSOComponent } from '@LoginComponent/pages/sso/sso.component';

const routes: Routes = [
  {
    path: 'customers',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.SuperAdmin, Roles.CustomerAdmin, Roles.CustomerReadOnly] },
    loadChildren: () => import('@CustomersComponent/customers.module').then((m) => m.CustomersModule),
  },
  {
    path: 'customer-portal',
    canActivate: [AuthCustomerGuard],
    /*data: { roles: [Roles.SuperAdmin, Roles.CustomerAdmin, Roles.CustomerReadOnly] },*/
    loadChildren: () => import('@CustomerPortal/customer-portal.module').then((m) => m.CustomerPortalModule),
  },
  {
    path: 'error',
    /*data: { roles: [Roles.SuperAdmin, Roles.CustomerAdmin, Roles.CustomerReadOnly] },*/
    loadChildren: () => import('@ErrorComponent/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'lessons',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.SuperAdmin, Roles.LessonAdmin, Roles.LessonReadOnly, Roles.LessonRestricted] },
    loadChildren: () => import('@LessonsComponent/lessons.module').then((m) => m.LessonsModule),
  },
  {
    path: 'resources',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.SuperAdmin, Roles.ResourcesAdmin, Roles.ResourcesReadOnly] },
    loadChildren: () => import('@ResourcesComponent/resources.module').then((m) => m.ResourcesModule),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.SuperAdmin, Roles.Reports] },
    loadChildren: () => import('@ReportsComponent/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'industrial-skills',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.SuperAdmin, Roles.IndSkillsAdmin] },
    loadChildren: () => import('@IndustrialSkills/industrial-skills.module').then((m) => m.IndustrialSkillsModule),
  },
  {
    path: 'preview',
    loadChildren: () => import('@PreviewsComponent/previewer.module').then((m) => m.PreviewerModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('@UserComponent/user.module').then((m) => m.UserModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Roles.SuperAdmin] },
    loadChildren: () => import('@AdminComponent/admin.module').then((m) => m.AdminModule),
  },
  { path: 'login', component: LoginPageComponent},
  { path: 'login/sso', component: SSOComponent},
  { path: 'passwordreset', component: PasswordResetPageComponent },
  { path: '', component: HomePageComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
